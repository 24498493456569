import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { EndPoint } from '../EndPointUrls/apiEndPoints.enum';
import { BillingStatus } from '../interfaces/billingstatus';
import { MyOrganization } from '../interfaces/IUser';
import { AppDefinitionApiUrl } from '../interfaces/workflowInterfaces';
// import { AuthService } from '../services/auth.service';
import { SitesHelperService } from '../services/helper.service';
import { LayoutService } from './service/app.layout.service';
import { environment } from 'src/environments/environment';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { AuthService } from '../services/auth.service';
import { BusinessAppsHttpService } from '../services/http.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./layout.scss'],
})
export class AppTopBarComponent implements OnInit {
    // items!: MenuItem[];

    showRightPanel: boolean = false;
    selectedOrganization?: any;
    bill?: BillingStatus;

    selectedAppDefinition: any;
    previewIcon: boolean = false;
    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        public authService: AuthService,
        protected router: Router,
        private httpService: BusinessAppsHttpService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private businessAppsService: SitesHelperService
    ) {
        this.previewIcon = environment.previewShow;
    }
    ngOnInit(): void {
        // const tenantKey = localStorage.getItem('tenantKey');
        // this.authService
        //     .getSubscriptions(`${EndPoint.subscription}/${tenantKey}`)
        //     .subscribe((bill: BillingStatus) => {
        //         this.bill = bill;
        //     });

        localStorage.removeItem('AppID');

    //     const claimsObj = JSON.parse(localStorage.getItem('id_token_claims_obj'));

    //     const organizationsClaims = claimsObj['extension_Organizations'] as string;
    //     const organizationPieces = organizationsClaims.split(",");
    
    //     console.log(organizationPieces);
    
    //     if (organizationPieces && organizationPieces.length > 0) {
    //       for (const org of organizationPieces) {
    //         console.log(org.split('~'));
    
    //         this.authService.myOrganizations.push({
    //           name: org.split('~')[0],
    //           tenantKey: org.split('~')[1],
    //           id: org.split('~')[2]
    //         })
    //       }
    //     }

    //     this.authService.activeOrganizationId = this.authService.myOrganizations[0].id;
    //     localStorage.setItem('organizationId', this.authService.activeOrganizationId);
        
    // this.authService.ActiveTenantKey = this.authService.myOrganizations[0].tenantKey;
    // localStorage.setItem('tenantKey', this.authService.myOrganizations[0].tenantKey);
    //get Organization Information
    // this.getActiveOrganizationInfo(this.authService.myOrganizations[0].tenantKey);
    

    }


    getActiveOrganizationInfo(tenantKey: string) {
        const url = `Organizations?$filter=tenantkey eq guid'${tenantKey}'`;
        this.httpService.get(url).subscribe(data => {
          console.log('Active ORg', data.items);
          this.authService.activeOrganizationId = data.items[0].id;
          localStorage.setItem('ActiveOrganizationID', data.items[0].id);
          this.authService.activeOrgAvailable = true;
        })
      }

    OnChangeOrganization($event: any) {
        this.selectedAppDefinition = null;
        localStorage.removeItem('AppID');
        this.businessAppsService.AppDefinitionChanged.next(null);

        // this.getActiveOrganizationInfo(this.authService.ActiveTenantKey);

        const selectedOrganization =
            this.authService.loggedInUser.myOrganizations.find(
                (org) => org.id === $event.value
            );

        localStorage.setItem(
            'organizationId',
            selectedOrganization.id
        );

        // this.authService.activeOrganizationId =  selectedOrganization.id;
        localStorage.setItem('tenantKey', selectedOrganization.tenantKey);

        localStorage.setItem(
            'isOrgAdmin',
            selectedOrganization.isAdministrator.toString()
        );

        localStorage.setItem('orgname', selectedOrganization.name);

        localStorage.setItem('saveOrgId', selectedOrganization.id);

        // this.authService.myAppDefinition = selectedOrganization.appDefinitions;

        this.authService.loadApp(true);
        // this.router.navigate(['/dashboard']);
    }

    onAppDefinitionChanged($event: any) {
        if ($event.value) {
            let appID = $event.value.id;
            this.businessAppsService.AppDefinitionChanged.next(appID);
            localStorage.setItem('AppID', appID);
            this.router.navigate(['/dashboard']);
        } else {
            this.businessAppsService.AppDefinitionChanged.next(null);
            localStorage.removeItem('AppID');
            this.router.navigate(['/dashboard']);
        }
    }

    logout() {

        let obApps = environment.Azure_AD_B2C.baseState.split(',');

        const logoutHint = localStorage.getItem('id_token');

        localStorage.clear();
        sessionStorage.clear();
        
        const activeAccount =
          this.msalService.instance.getActiveAccount() ||
          this.msalService.instance.getAllAccounts()[0];
    
        this.msalService.logoutRedirect({
          account: activeAccount,
          idTokenHint: logoutHint,
          postLogoutRedirectUri: `${environment.Azure_AD_B2C[obApps[0]]}logout`,
          state: obApps.join(',')
        });
    }
}
